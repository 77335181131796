<template>
	<div 
		class="overview view content-padding"
		:class="{ 'no_pagination': !checkPagination }"
	>

    <SvgLoader v-if="!isDataLoaded" :loading="true"  :bgColor="'transparent'" :width="'100%'"/>

		<div v-if="isDataLoaded" class="overview__card">
			<div
				class="overview__col"
				cols="12"
				v-for="index in 5"
				v-bind:key="index"
				v-show="!statusLoadingSkeleton"
			>
				<SkeletonCard/>
			</div>
			<div
				v-show="statusLoadingSkeleton"
				cols="12"
				class="overview__col"
				v-for="item in entityData"
				v-bind:key="`calculation-${ item._id }`"
			>
				<MediaCard
					class="overview-card"
					v-bind:title="item.title"
					v-bind:media="item.media"
					v-bind:link="item.link"
					v-bind:open-label="labels.open"
				/>
			</div>
			<div
				cols="12"
				class="overview__col"
			>
				<AddPanel
					id="v-step-0"
					class="overview__add"
					v-bind:label="labels.add_media"
					v-bind:buttons="createNewObject"
				/>
			</div>
		</div>

		<div
			v-if="Object.entries( pagination ).length && (items.total / (items.limit || 20)) > 1 && isDataLoaded"
			class="overview__pagination"
		>
			<Pagination
				:total-rows="items.total"
				:per-menu="items.limit || 20"
				:current-pagination="pagination.current"
				@paginationChanged="paginationChanged"
			/>
		</div>

		<router-view
			name="create_media"
			kind="media"
			modal-id="1"
			v-on:entity-created="redirectToEntity( $event )"
			v-bind:title="labels.add_new_media"
			v-bind:title-placeholder="labels.enter_media_name"
			v-bind:text-placeholder="labels.enter_media_description"
			v-bind:media-placeholder="labels.select_media"
			v-bind:route-back="{name: 'media_overview'}"
			v-bind:required-fields="['title', 'text', 'media']"
			v-bind:required-data-set="['title', 'text', 'media']"
		/>
	</div>
</template>

<script>
	import AddPanel from "@/components/Panels/AddPanel";
	import SkeletonCard from "@/components/Cards/SkeletonCard";
	import Pagination from "@/components/Helpers/Pagination";
	import MediaCard from "@/components/Cards/MediaCard";
	import SvgLoader from "@/components/Preloaders/SvgLoader";

	import { mapActions, mapMutations, mapState, mapGetters } from "vuex";

	export default {
		name: "MediaOverview",
		components: {
			MediaCard,
			AddPanel,
			SkeletonCard,
			Pagination,
			SvgLoader
		},
		data() {
			return {
				pagination: {},
				items: {},
				isDataLoaded: false
			}
		},
		computed: {
			...mapGetters('helper', ['labels']),
			...mapState( 'media', {
				entityList: 'mediaList',
				permissions: 'mediaOverviewPermissions',
				statusLoadingSkeleton: "statusLoadingSkeleton"
			} ),
			checkPagination() {
				return Object.entries( this.pagination ).length && (this.items.total / (this.items.limit || 20)) > 1;
			},
			entityData() {
				let res = [];

				if (Array.isArray(this.entityList) && this.entityList.length) {
					this.entityList.forEach( ( item, index ) => {
						res[index] = {
							_id: item._id,
							title: item.title,
							media: item.media,
							link: `/dashboard/media/${ item._id }`,
						}
					} );
				} else {
					console.log('Entity list for media is:', this.entityList);
				}

				return res;
			},

			createNewObject() {
				return [
					{
						_id: 1,
						text: '',
						link: `/dashboard/media/create`,
						backgroundColor: "#004388",
						enabled: (() => {
							// return this.currentUserCan( 'create' );
							return true;
						})()
					},
				];
			},

			currentPage() {
				let page = 1;

				if ( this.pagination && this.pagination.current ) {
					if ( (this.items.total / (this.items.limit || 20)) <= 1 ) {
						page = 1;
					} else {
						page = this.pagination.current;
					}
				} else if ( this.$route.query.page ) {
					page = this.$route.query.page;
				}
				return page;
			},
		},
		methods: {
			...mapMutations( 'helper', {
				setBreadcrumbs: 'setBreadcrumbs'
			} ),
			...mapActions( 'notification', {
				addNotification: 'addNotification'
			} ),
			...mapMutations( 'media', {
				setStatusLoadingSkeleton: 'setStatusLoadingSkeleton'
			} ),
			...mapActions( "media", {
				getEntityList: 'getList',
			} ),

			// currentUserCan( action ) {
			// 	if ( this.permissions && this.permissions[action] && this.permissions[action] === 1 ) {
			// 		return true;
			// 	}
			// 	return false;
			// },
			paginationChanged(page){
				this.$router.replace({ name: 'media_overview', query: { page: page } })
			},
			setQueryPage() {
				if ( !this.$route.query.page || this.$route.query.page != this.currentPage ) {
					this.$router.push( {
						path: this.$route.path,
						query: { page: this.currentPage }
					} );
				}
			},

			getEntityListMt( page ) {
				this.isDataLoaded = false
				let data = {
					page: this.currentPage,
				};
				if ( page ) data.page = page;

				if ( this.$route.query.user ) {
					data.user = true;
				}

				let self = this;
				this.getEntityList( data )
				.then( res => {
					if ( res.success ) {
						self.setStatusLoadingSkeleton( true );
						self.items = { ...res.data.items };
						self.pagination = { ...res.data.pages };
						this.isDataLoaded = true
					} else if ( res.error ) {
						self.addNotification( { variant: 'danger', msg: res.error, labels: self.labels } );
					}
				} )
				.catch( err => console.log( err ) );
			},

			redirectToEntity() {
				this.getEntityListMt()
			},
		},
		mounted() {
			this.setBreadcrumbs( { title: '' } );
			this.getEntityListMt();
			this.setStatusLoadingSkeleton( false );
		},
		watch: {
			$route( to, from ) {
				if ( to.name === 'create_media' || from.name === 'create_media' ) {
					return;
				}

				let fromPage = '1';
				if ( from.query.page ) fromPage = from.query.page;

				if (
					to.query.page !== from.query.page &&
					to.name === from.name &&
					this.currentPage == fromPage &&
					!( !from.query.page && to.query.page == '1' )
				) {
					let page = to.query.page ? to.query.page : '1';
					this.setStatusLoadingSkeleton( false );
					this.getEntityListMt( page );
				}
			},
			currentPage( newValue, oldValue ) {
				let queryPage = '1';
				if ( this.$route.query.page ) queryPage = this.$route.query.page;

				if ( Number( newValue ) !== Number( oldValue ) && oldValue == queryPage ) {
					this.getEntityListMt();
					this.setQueryPage();
				}
			},
		},
	}
</script>

<style lang="scss" scoped>
	.overview {
		min-height: 100%;
		display: flex;
		flex-direction: column;

		&__card {
			display: flex;
			flex-wrap: wrap;
		}

		&.no_pagination {
			margin-bottom: -20px;

			@media (min-width: 992px) {
				margin-bottom: -30px;
			}
			@media (min-width: 1600px) {
				margin-bottom: -40px;
			}
		}

		&__col {
			flex: 0 0 25%;
			max-width: 25%;
			width: 100%;
			padding-right: 15px;
    		padding-left: 15px;
			margin-bottom: 20px;

			@media (max-width: 1200px) {
				flex: 0 0 33%;
				max-width: 33.3%;
			}

			@media (max-width: 767px) {
				flex: 0 0 50%;
				max-width: 50%;
			}

			@media (max-width: 450px) {
				flex: 0 0 100%;
				max-width: 100%;
			}

			@media (min-width: 992px) {
				margin-bottom: 30px;
			}

			@media (min-width: 1600px) {
				margin-bottom: 40px;
			}
		}

		.jumbotron {
			margin: 0;
		}

		&__pagination {
			margin-top: auto;
			display: flex;
			justify-content: center;

			::v-deep .pagination.b-pagination {
				margin-bottom: 0;
			}
		}
	}
</style>